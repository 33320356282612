import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import { fetchAddressByPincode, fetchGetShopByPinCode, fetchSaveAddress } from "../../redux/Actions/checkoutPageActions";
import {
  service1,
  service2,
  service3,
  service4,
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  washwave,
  priorityicon1,
  award,
  eco,
  priorityicon2,
  priorityicon3,
  priorityicon4,
  priorityicon5,
  priorityicon6,
  priorityicon7,
  priorityicon8,
  priorityicon9,
  priorityicon10,
  priorityicon11,
  priorityicon12,
  priorityicon13,
  priorityicon14,
  priorityicon15,
  priorityicon16,
  priorityicon17,
  priorityimg1,
  priorityimg2,
  priorityimg3,
  appdownload,
  mobicon1,
  mobicon2,
  mobicon3,
  appstore,
  googleplay,
  qrcode,
  laundrybag1,
  wing1,
  wing2,
  dicon1,
  dicon2,
  dicon3,
  dicon4,
  dicon5,
  dicon6,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  faqimg,
  trustimg,
  trustimg1,
  trustimg2,
  trustimg3,
  trustimg4,
  trustimg5,
  heroimg,
  howwe1,
  howwe2,
  howwe3,
  howwe4,
  cateimg1,
  cateimg2,
  cateimg3,
  cateimg4,
  cateimg5,
  userimage,
  howstep1,
  howstep2,
  howstep3,
} from "../../assets/img";
import SampleVideo from "./../../images/sample.mp4";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { Pagination } from "swiper";

import axios from "axios";
import { DebounceInput } from "react-debounce-input";

import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import GoogleReviews from "../googleReview";
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

const searchPinCodeFormSchema = yup.object().shape({
    pinCode: yup
      .string()
      .required("Postcode is required")
      .matches(UKPostalCode, "Enter a valid Postcode")
      .trim()
      .max(8, "Enter a valid Postcode"),
  });
  
  const addressFormSchema = yup.object().shape({
    address: yup
      .object()
      .shape({
        label: yup.string().required("Address is required to proceed"),
        value: yup.string().required("Address is required to proceed"),
      })
      .test("address.value", "Address is required to proceed", (value: any) => {
        if (value.value == "") {
          return false;
        } else return true;
      })
      .required("Address is required to proceed"),
  });
  

function BookNow() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const navigate = useNavigate();
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });

  // const base_url = "https://revamp.dreamguystech.com"

  // const fetchData = async () => {
  //     const base_url = "https://revamp.dreamguystech.com";
  //     try {
  //         const bodyFormData = new FormData();
  //         bodyFormData.append('keyword', 'Gu11');
  //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
  //         const response = await axios({
  //             method: "post",
  //             url: `${base_url}/api/get_postal_codes`,
  //             data: bodyFormData,
  //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
  //         })
  //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
  //         // const result = await response.then(response => response);

  //         if (response) {
  //             setPostalCodeList(response.data.data[0])
  //
  //         }
  //     } catch (error) {
  //
  //     }
  // }
  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);
  const settingsData: any = useSelector<any>((state) => state?.settings);

  // const [productList, setProductList] = useState<any | null>({})
  const [productList, setProductList] = useState<any | null>({});
  const [blogList, setBlogList] = useState<any | null>({});
  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });

    // axios.get(`${base_url}/api/get_categories?group_id=1`, {
    //     headers: headers

    // }).then(e => {
    //     setProductList({ data: e.data })
    //     // e.data.data.category.slice(0, 1).map((category: any) =>
    //     //     setActive(category.main_category_name)
    //     // )
    // })

    axios
      .get(`${base_url}/api/blog_list?`, {
        headers: headers,
      })
      .then((e) => {
        setBlogList(e.data.data);
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  const faqData: any = useSelector<any>((state) => state?.faq);
  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  // useEffect(() => {
  //     // fetchData();
  //     dispatch(fetchPostalCodes())
  //     // dispatch(fetchPostalCodes());
  // }, [])

  const handleChange = (e: any) => {
    const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g, "");
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
    // dispatch(fetchPostalCodes(searchTerm));
  };
  const handleBooknow = async () => {
    const isValid = state.postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );
    setValue("postalCode", postalCodeValue.toUpperCase());
    const results = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(postalCodeValue))
    );

    if (!results) {
      navigate("/areaNotCovered");
      return;
    }
    if (results) {
      navigate("/productLists");
    } else {
      navigate("/areaNotCovered");
    }
  };

  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };

  const submitAddressChange = async () => {
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          navigate("/productLists");
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        navigate("/productLists");
      }
    }
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };

  return (
    <>
      <div className="home_single_search">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="search-wrapper">
                <h4>Get your tailoring, laundry & dry cleaning in 24h</h4>
                <div className="postcodeform-container">
                  <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                    <div
                      className={`${checkWithNewPin == false ? "d-none" : ""}`}
                    >
                      <small className="text-danger postvalidation">
                        {pinCodeFormError.pinCode?.message}
                      </small>
                      <div className="postcodeform">
                        <Controller
                          name="pinCode"
                          control={pinCodeFormControl}
                          render={({ field }) => (
                            <input
                              type="text"
                              id="postcode-input"
                              {...field}
                              placeholder="Enter Postcode"
                              className={`ui-autocomplete-input ${
                                pinCodeFormError.pinCode ? "invalid" : ""
                              }`}
                            />
                          )}
                        />
                        <button
                          className="btn hover-btn"
                          id="book_now_btn"
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                  <form>
                    <div
                      className={`adrsselectform ${
                        checkWithNewPin == true ? "d-none" : ""
                      }`}
                    >
                      <small className="text-danger postvalidation">
                          {addressFormError.address?.message}
                        </small>
                      <div className="adrsselectdd">
                        <Controller
                          name="address"
                          control={addressFormControl}
                          render={({ field }) => (
                            <Select
                              placeholder="Select your address"
                              {...field}
                              value={selectedAddress}
                              className={`${
                                addressFormError.address ? "invalid" : ""
                              }`}
                              onChange={handleAddressChange}
                              onFocus={() => {
                                if (getValuesAddressForm("address").value == "") {
                                  addressFormSetError("address", {
                                    type: "server",
                                    message: "Address is required to proceed",
                                  });
                                } else {
                                  clearErrors();
                                }
                              }}
                              options={areaOptions}
                            />
                          )}
                        />
                      
                        <input
                          type="button"
                          value="Continue"
                          className="btn hover-btn"
                          onClick={submitAddressChange}
                        />
                      </div>
                      <div className="adrsselect-or">OR</div>
                      <div className="adrsselect-link">
                        <a
                          className="cursor-pointer"
                          onClick={() => {
                            setCheckWithNewPin(true);
                          }}
                        >
                          Check with another Postcode
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="banner-search-wrap d-block">
                <Swiper
                  spaceBetween={15}
                  pagination={true}
                  modules={[Pagination]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  <SwiperSlide>
                    <div className="testimonial-card">
                      <div className="profile-user">
                        <a className="profile-user-img">
                          <img
                            src={testimonialuser1}
                            className="rounded-circle"
                          />
                        </a>
                        <div className="profile-user-info">
                          <h6>
                            <a>Natalie</a>
                          </h6>
                          <div className="profile-rating">
                            <span className="secondary-text">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </span>
                            <span>5.0</span>
                          </div>
                        </div>
                      </div>
                      <p>
                      I had a dress altered at Jaya Luxury Garment Care and couldn't be more pleased with the result! It was a tricky alteration with three layers of very delicate material. It now fits beautifully and I am really pleased with how the dress looks. He was professional and lovely to do business with! Would definitely recommend and use again for future fittings.
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testimonial-card">
                      <div className="profile-user">
                        <a className="profile-user-img">
                          <img
                            src={testimonialuser2}
                            className="rounded-circle"
                          />
                        </a>
                        <div className="profile-user-info">
                          <h6>
                            <a>Melli Got</a>
                          </h6>
                          <div className="profile-rating">
                            <span className="secondary-text">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </span>
                            <span>5.0</span>
                          </div>
                        </div>
                      </div>
                      <p>
                      Felt welcomed as soon as I entered the door!
The staff were very friendly and true professionals!
I brought them a cream hoodie which was covered in wine, which I had no hopes for but they managed to bring it back to its original state.
They done exactly what I needed from them & I'm very satisfied with the results.
They've gained a loyal customer!
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="testimonial-card">
                      <div className="profile-user">
                        <a className="profile-user-img">
                          <img
                            src={testimonialuser3}
                            className="rounded-circle"
                          />
                        </a>
                        <div className="profile-user-info">
                          <h6>
                            <a>Aisha B</a>
                          </h6>
                          <div className="profile-rating">
                            <span className="secondary-text">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </span>
                            <span>5.0</span>
                          </div>
                        </div>
                      </div>
                      <p>
                      I came in with a structured boned corset that was slightly too big. I needed the alterations to be done in time for my birthday which gave Rafi only a few days to do this but he did an exceptional job. Fantastic customer service and always willing and ready to help. An extremely professional business I am definitely coming back with more items!!! :D
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="ourservice-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="section-title mb-3">
                <h2>Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <Swiper
                spaceBetween={15}
                pagination={true}
                modules={[Pagination]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="ourservice-grid">
                    <a href="#" onClick={(e)=>{e.preventDefault(); navigate('/pricing');}}><img src={service1} alt="" className="img-fluid" /></a>
                    <h4>Wash & Iron</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="ourservice-grid">
                    <a href="#" onClick={(e)=>{e.preventDefault(); navigate('/pricing');}}><img src={service2} alt="" className="img-fluid" /></a>
                    <h4>Dry Cleaning</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="ourservice-grid">
                    <a href="#" onClick={(e)=>{e.preventDefault(); navigate('/pricing');}}><img src={service4} alt="" className="img-fluid" /></a>
                    <h4>Laundry</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="ourservice-grid">
                    <a href="#" onClick={(e)=>{e.preventDefault(); navigate('/pricing');}}><img src={service3} alt="" className="img-fluid" /></a>
                    <h4>Alteration & Repair</h4>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="review-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="review-info">
                <div className="review-star">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <p>
                    <a
                      className="text-white"
                      href="https://g.co/kgs/s5n3Bf"
                      target="_blank"
                    >
                      Read more Reviews
                    </a>
                  </p>
                </div>
                <h2>
                  <span>4.8 Best Rated Service </span>in East London
                </h2>
              </div>
            </div>
            <div className="bg-white p-5 mt-3">
              <GoogleReviews />
            </div>
          </div>
        </div>
      </section>
      <section className="howwe-section" id="how-work-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>How we Work</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="how-work-inner">
                  <div className="how-work-wrapper">
                      <div className="work-item">
                          <div className="work-inner">
                              <div className="work-img">
                                  <span>Step 1</span>
                                  <img src={howstep1} alt="Laundry Pickup" />                                
                              </div>
                              <div className="work-content">
                                  <p>Choose <strong>when</strong> and <strong>where</strong> you wish us to collect &amp; deliver your laundry.</p>
                              </div>
                          </div>
                      </div>
                      <div className="work-item">
                          <div className="work-inner">
                              <div className="work-img">
                                  <span>Step 2</span>
                                  <img src={howstep2} alt="Laundry Collection" />
                              </div>
                              <div className="work-content">
                                  <p>We <strong>collect</strong> your bag, <strong>list</strong> and <strong>clean</strong> your items according to <strong>your requirements</strong>.</p>
                              </div>
                          </div>
                      </div>
                      <div className="work-item">
                          <div className="work-inner">
                              <div className="work-img">
                                  <span>Step 3</span>
                                  <img src={howstep3} alt="Laundry Delivery" />
                              </div>
                              <div className="work-content">
                                  <p>We <strong>deliver</strong> your items cleaned within <strong>24 hours</strong> and at the time required.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              {/* <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                className="home-slider home-slider-howwe"
              > */}
                {/* <SwiperSlide>
                  <div className="card-howwe">
                    <div className="card-howwe-img">
                      <img src={howwe1} alt="" />
                      <span>1</span>
                    </div>
                    <h3>Online Order</h3>
                    <p>
                      Use our friendly and easy access website or app to place
                      your laundry order. choose a suitable pickup location and
                      time.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-howwe">
                    <div className="card-howwe-img">
                      <img src={howwe2} alt="" />
                      <span>2</span>
                    </div>
                    <h3>Transport</h3>
                    <p>
                      Our registered driver will pick up your laundry at your
                      doorstep and transport the laundry safely to one of our
                      many trusted dry cleaning facilities.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="card-howwe">
                    <div className="card-howwe-img">
                      <img src={howwe4} alt="" />
                      <span>3</span>
                    </div>
                    <h3>Door Delivery</h3>
                    <p>
                      Dry and clean clothes were carefully bagged and delivered
                      to your doorstep.
                    </p>
                  </div>
                </SwiperSlide> */}
                {/* <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe1} alt=""/>
                                            <span>1</span>
                                        </div>
                                        <h3>Online Order</h3>
                                        <p>Use our friendly and easy access website or app to place your laundry order. choose a suitable pickup location and time.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe2} alt=""/>
                                            <span>2</span>
                                        </div>
                                        <h3>Transport</h3>
                                        <p>Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe3} alt=""/>
                                            <span>3</span>
                                        </div>
                                        <h3>Dry Cleaning</h3>
                                        <p>Our registered driver will pick up your laundry at your doorstep and transport the laundry safely to one of our many trusted dry cleaning facilities.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-howwe'>
                                        <div className='card-howwe-img'>
                                            <img src={howwe4} alt=""/>
                                            <span>4</span>
                                        </div>
                                        <h3>Door Delivery</h3>
                                        <p>Dry and clean clothes were carefully bagged and delivered to your doorstep.</p>
                                    </div>
                                </SwiperSlide> */}
              {/* </Swiper> */}
            </div>
          </div>
        </div>
      </section>
      <section className="serv-section d-none" id="service-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>service for Business & Personal</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="split-img">
                <img src={trustimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="serv-content">
                <h4>For Business</h4>
                <ul>
                  <li>Restaurants</li>
                  <li>Spa</li>
                  <li>Hotels</li>
                  <li>Gym</li>
                  <li>Factory</li>
                  <li>Malls</li>
                  <li>Public sectors</li>
                  <li>Hospitals</li>
                </ul>
              </div>
              <div className="serv-content">
                <h4>For Personal</h4>
                <ul>
                  <li>Home Stay</li>
                  <li>Local Living</li>
                  <li>Hostel</li>
                  <li>Cottage</li>
                  <li>PG Stay</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cate-section d-none">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>Our Product Category</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 p-0">
              <Swiper
                spaceBetween={1}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 8,
                  },
                }}
                className="home-slider"
              >
                {/* {Object.keys(productList).length > 0 && productList?.data.data.category.map((item: any) => {
                                    return <SwiperSlide>
                                        <div className='card-cate'>
                                            <img src={item.web_banner_img} alt=""/>
                                            <div className='card-cate-body'>
                                                <p className='secondary-text'>{item.sub_categories.length} Items</p>
                                                <h3>{item.main_category_name}</h3>
                                                <a href='#' onClick={(e)=>{e.preventDefault(); navigate('pricing');}} className='btn primary-btn rounded-circle hover-btn'><i className='uil uil-arrow-right'></i></a>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                })} */}
                {Object.keys(productList).length > 0 &&
                  productList?.data.data.category.map((item: any) => {
                    return (
                      <SwiperSlide>
                        <div className="card-cate">
                          <img src={item.web_banner_img} alt="" />
                          <div className="card-cate-body">
                            <a
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                navigate("pricing");
                              }}
                            >
                              {item.main_category_name}
                              <i className="uil uil-arrow-right ms-2"></i>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                {/* <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg2} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Iron<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg3} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Dry<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg4} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Cleaning<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg5} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Gentle Wash<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg1} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Wash<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg2} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Iron<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg3} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Dry<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg4} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Cleaning<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='card-cate'>
                                        <img src={cateimg5} alt=""/>
                                        <div className='card-cate-body'>
                                            <a href="">Gentle Wash<i className='uil uil-arrow-right ms-2'></i></a>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className="safety-section">
        <div className="container">
          <div className="safety-info-grid">
            <div className="row">
              <div className="col-md-5">
                <div className="split-img">
                  <img src={trustimg5} alt="" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="section-title text-start mt-3">
                  <h2 className="ps-0 mb-0">About Us</h2>
                  {/* <h4>Step we follow for Health & Safety Precaution</h4> */}
                  <h4 className="p-3" style={{lineHeight: '2'}}>Welcome to our family-owned dry cleaning business, where we've been taking care of clothes for over 40 years. We're all about giving your garments the best treatment possible. From the smallest stitch to the finest fabric, we handle each piece with care and passion. We're here to bring life back to your clothes and make them look their best. Join us and experience over four decades of expertise in making your attire shine.</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-3">
              <div className="safety-list-card-desc">
                <img src={eco} alt="" />
                <h3 className="text-white">
                  We use Eco-friendly Laundry Detergent Sheets.
                </h3>
              </div>
            </div>
            <div className="col-md-3 d-flex">
              <div className="safety-list-card safety-list-card-bg1">
                <h3>Vegan</h3>
                <p>
                  Our vegan-friendly laundry detergent is free from any animal
                  product.
                </p>
              </div>
            </div>
            <div className="col-md-3 d-flex">
              <div className="safety-list-card safety-list-card-bg2">
                <h3>Cruelty Free</h3>
                <p>
                  We never test on animals – so our Laundry Leaves will always
                  be cruelty-free.
                </p>
              </div>
            </div>
            <div className="col-md-3 d-flex">
              <div className="safety-list-card safety-list-card-bg3">
                <h3>Eco-Friendly</h3>
                <p>
                  Free from any harmful chemicals or pollutants and are devoid
                  of harmful toxins.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="section-title text-start">
                <h2 className="ps-0">Frequently Asked Question</h2>
              </div>
              <div className="faq-content">
                <Accordion className="faq-home">
                  {faqData?.slice(0, 6).map((item: any) => {
                    return (
                      <Accordion.Item eventKey={JSON.stringify(item?.id)}>
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body>{item.answer}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
            <div className="col-md-6">
              <div className="split-img">
                <img src={trustimg4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonial-section d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>Client Testimonials</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="testimonial-card">
                  <div className="profile-user">
                    <a className="profile-user-img">
                      <img src={testimonialuser1} className="rounded-circle" />
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Durso Raeen</a>
                      </h6>
                      <div className="profile-rating">
                        <span className="secondary-text">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </span>
                        <span>5.0</span>
                      </div>
                    </div>
                  </div>
                  <p>
                    My suits came back quickly and felt newer than the day I
                    bought them great that was easy to use,best part they came
                    to pick them up and drop them off for me.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonial-card">
                  <div className="profile-user">
                    <a className="profile-user-img">
                      <img src={testimonialuser2} className="rounded-circle" />
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Camelia Rennesa</a>
                      </h6>
                      <div className="profile-rating">
                        <span className="secondary-text">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </span>
                        <span>5.0</span>
                      </div>
                    </div>
                  </div>
                  <p>
                    My suits came back quickly and felt newer than the day I
                    bought them great that was easy to use,best part they came
                    to pick them up and drop them off for me.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonial-card">
                  <div className="profile-user">
                    <a className="profile-user-img">
                      <img src={testimonialuser3} className="rounded-circle" />
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Brayan</a>
                      </h6>
                      <div className="profile-rating">
                        <span className="secondary-text">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </span>
                        <span>5.0</span>
                      </div>
                    </div>
                  </div>
                  <p>
                    My suits came back quickly and felt newer than the day I
                    bought them great that was easy to use,best part they came
                    to pick them up and drop them off for me.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-section d-none" id="services-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title">
                On Demand <span>Laundry, Dry Cleaning and Ironing</span> Service
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="services-slider">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  {Object.keys(productList).length > 0 &&
                    productList.data.data.category.map((item: any) => {
                      return (
                        <SwiperSlide>
                          <div className="services-slider-grid">
                            <img src={item.web_banner_img} alt="" />
                            <h5>{item.main_category_name}</h5>
                            {/* <p>There are many variations of passages of Lorem Ipsum available.</p> */}
                            <a
                              href="#bookNow"
                              className="btn btnslider hover-btn"
                            >
                              Book Now<i className="fas fa-chevron-right"></i>
                            </a>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="latestnews-section" id="news-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>Blogs & Events</h2>
                {/* <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                className="home-slider"
              >
                {blogList?.length > 0 && (
                  <SwiperSlide>
                    <div className="latestnews-card">
                      <div className="latestnews-img">
                        <img className="blog" src={blogList[0].grid_sm_img} alt="" />
                        <div className="latestnews-info">
                          <div className="latestnews-top">
                            {/* <span className='latestnews-tag'>{blogList[0].category_name}</span> */}
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="latestnews-bottom">
                            <div className="profile-user">
                              {/* <a className='profile-user-img'>
                                                            <img src={blogList[0].grid_sm_img}  className="rounded-circle" />
                                                        </a> */}
                              <div className="profile-user-info latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Orlando Waters</a></h6> */}
                                <span className="latestnews-date">
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[0].formatted_date}{" "}
                                  {blogList[0].formatted_month}{" "}
                                  {blogList[0].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                className="text-white"
                                href={`${blogUrl}/blogg/${blogList[0].id}/${blogList[0].url_slug}`}
                              >
                                {blogList[0].page_title.substring(0, 80)}
                                {blogList[0].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            <p className="text-white">
                              {blogList[0].preview_text.substring(0, 95)}
                              {blogList[0].preview_text.length > 95
                                ? "..."
                                : ""}
                            </p>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {blogList?.length > 1 && (
                  <SwiperSlide>
                    <div className="latestnews-card">
                      <div className="latestnews-img">
                        <img className="blog" src={blogList[1].grid_sm_img} alt="" />
                        <div className="latestnews-info">
                          <div className="latestnews-top">
                            {/* <span className='latestnews-tag'>{blogList[1].category_name}</span> */}
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="latestnews-bottom">
                            <div className="profile-user">
                              {/* <a className='profile-user-img'>
                                                            <img src={blogList[1].grid_sm_img}  className="rounded-circle" />
                                                        </a> */}
                              <div className="profile-user-info latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Richard Carg</a></h6> */}
                                <span className="latestnews-date">
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[1].formatted_date}{" "}
                                  {blogList[1].formatted_month}{" "}
                                  {blogList[1].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                className="text-white"
                                href={`${blogUrl}/blogg/${blogList[1].id}/${blogList[1].url_slug}`}
                              >
                                {blogList[1].page_title.substring(0, 80)}
                                {blogList[1].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            <p className="text-white">
                              {blogList[1].preview_text.substring(0, 95)}
                              {blogList[1].preview_text.length > 95
                                ? "..."
                                : ""}
                            </p>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
                {blogList?.length > 2 && (
                  <SwiperSlide>
                    <div className="latestnews-card">
                      <div className="latestnews-img">
                        <img className="blog" src={blogList[2].grid_sm_img} alt="" />
                        <div className="latestnews-info">
                          <div className="latestnews-top">
                            {/* <span className='latestnews-tag'>{blogList[2].category_name}</span> */}
                            {/* <span className='latestnews-addfav rounded-circle'><i className='uil uil-favorite'></i></span> */}
                          </div>
                          <div className="latestnews-bottom">
                            <div className="profile-user">
                              {/* <a className='profile-user-img'>
                                                            <img src={blogList[2].grid_sm_img}  className="rounded-circle" />
                                                        </a> */}
                              <div className="profile-user-info latestnews-user-info">
                                {/* <h6><a target='_blank' href="">Andrea Masadoni</a></h6> */}
                                <span className="latestnews-date">
                                  <i className="uil uil-calendar-alt me-2"></i>
                                  {blogList[2].formatted_date}{" "}
                                  {blogList[2].formatted_month}{" "}
                                  {blogList[2].formatted_year}
                                </span>
                              </div>
                            </div>
                            <h3>
                              <a
                                target="_blank"
                                className="text-white"
                                href={`${blogUrl}/blogg/${blogList[2].id}/${blogList[2].url_slug}`}
                              >
                                {blogList[2].page_title.substring(0, 80)}
                                {blogList[2].page_title.length > 80
                                  ? "..."
                                  : ""}
                              </a>
                            </h3>
                            <p className="text-white">
                              {blogList[2].preview_text.substring(0, 95)}
                              {blogList[2].preview_text.length > 95
                                ? "..."
                                : ""}
                            </p>
                            {/* <div className='latestnews-arlk-count'>
                                                        <span className='arlk-count-tag'><i className='uil uil-heart-sign me-2'></i>45</span>
                                                        <span className='arlk-count-tag'><i className='uil uil-comment-alt me-2'></i>40</span>
                                                        <span className='ms-auto'><i className='uil uil-stopwatch me-2'></i>10 Min To Read</span>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BookNow;
