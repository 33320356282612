import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { appstore, googleplay, paymethod1, paymethod2, paymethod3, paymethod4, qrscan, logo, payment_methods } from '../../assets/img'
import { useDispatch, useSelector } from "react-redux";

export const SET_SETTINGS = "SET_SETTINGS";

export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload
});

function Footer() {
  const navigate = useNavigate();
  const Url = window.location.host
  const blogUrl = `https://blog.${Url}`

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const appStorel = Object.keys(settings).length > 0 && settings.WebmasterSettings.app_store_link
  const playStore = Object.keys(settings).length > 0 && settings.WebmasterSettings.play_store_link
  const facebook = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1
  const twitter = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2
  const instagram = Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6
  const dispatch = useDispatch<any>();

  const [areaCovered, setAreaCovered] = useState<any | null>({})

  const settingsData: any = useSelector<any>(
      (state) => state?.settings
  );

  useEffect(() => {
    if (Object.keys(settings).length === 0 && settingsData == undefined) {
      axios({
        method: "get",
        url: `${base_url}/api/settings`,
        headers: {
          "Accept": "application/json",
          'Access-Control-Allow-Methods': 'GET, POST',
        },
      }).then((response) => {
        if (response.status != 401) {
          setSettings(response.data.data);
          dispatch(setSettingsAll(response.data.data))
        }
      }).catch(function (error) {
        
      })
    }

    const headers = {
      'Accept': 'application/json'
    }

    axios.get(`${base_url}/api/areas_covered`, {
      headers: headers

    }).then(e => { setAreaCovered({ data: e.data }) })
  }, [])

  
  const popAreadCovered = (areaList: any) => {



    var test = Object.keys(areaList).map((location, locationindex) => {

      let cityArr = areaList[location].toString().split(',');

      return (<>
        {cityArr.map((city: any) => <li><a href='' onClick={(e) => {
          e.preventDefault(); navigate('/dry-cleaners/' + city)
        }}>{city}</a></li>)}
      </>)
    })

    return test

  }

  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
    <div>
      <footer className="footer">
        <div className="container">
          
        <div className="row footer-sectiontwo">
            <div className="col-md-12">
              <div className='logo-footer'>
                { Object.keys(settings).length > 0 ? 
                  <img src={logo} alt="logo"/>
                :
                  <img src={logo} alt="logo"/>
                }
                </div>
              {/* <div className="home-links">
                <ul>
                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}} className="nav-bar-link" to={'/aboutUs'}>About Us</NavLink></li>
                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/areaCovered'}>Area Covered</NavLink></li>

                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/faq'}>FAQ</NavLink></li>
                  <li><NavLink style={({isActive})=>{return{color: isActive?'#efc10b':''}}}  className="nav-bar-link" to={'/reviews'}>Reviews</NavLink></li>
                  <li><a target="_blank" href={blogUrl}>Blog</a></li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="row footer-sectionthree text-center">
            <div className="col-md-4 col-sm-12">
              <div className="footerlist-one">
                <h4>Quick Links</h4>
                <ul>
                  <li><a href='' onClick={(e)=>{e.preventDefault(); navigate('/aboutUs')}}>About Us</a></li>
                  <li><a href="#how-work-section">How it works</a></li>
                </ul>
              </div>
            </div>
            <div
                className="col-md-4 col-sm-6">
                  <div
                    className="footerlist-two">
                    <h4>Quick Connect</h4>
                    {appStorel == "" && playStore == "" && "Coming Soon"}
                    {appStorel != "" && playStore != "" &&
                      <div
                        className="app-icons">
                        <ul
                          className="list-inline">
                          <li><a target={appStorel == ""? '' : "_blank"} href={appStorel == ""? 'javascript:;' : appStorel} className="hvr-float-shadow imggbg"><img src={appstore} className="img img-fluid" alt="appstore" /></a></li>
                          <li><a target={playStore == ""? '' : "_blank"} href={playStore == ""? 'javascript:;' : playStore} className="hvr-float-shadow imggbg2"><img src={googleplay} className="img img-fluid" alt="appstore" /></a></li>
                        </ul>
                      </div>
                    }
                    
                  </div>
              </div>
            <div className="col-md-4 col-sm-12">
                <div
                  className="footerlist-two">
                  <h4>Contact</h4>
                  <ul>
                    <li>
                      <p>
                      8 Leytonstone Rd, London E15 1SE, UK
                      </p>
                    </li>
                    <li>
                      <h6>Call us</h6>
                      <a href='mailto:info@luxurygarmentcare.co.uk'>info@luxurygarmentcare.co.uk</a>
                    </li>
                    <li>
                      <h6>Phone number</h6>
                      <a href='07475 555358'>07475 555358</a>
                    </li>
                  </ul>
                </div>
            </div>
            {/* <div className="col-md col-sm-12">
              <h4>Work with us</h4>
              <ul>
                <li><a href=''>Careers</a></li>
                <li><a href=''>Partner drivers</a></li>
                <li><a href=''>Cleaning partners</a></li> 
              </ul>
            </div> */}
            {/* <div className="col-md col-sm-12">
              <div className="footer-two">
                <div
                  className="footerlist-two">
                  <h4>We are here for you..</h4>
                  <ul>
                    <li><span>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t1_en}</span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Need Help?</h4>
                  <ul>
                    <li><span>Phone :</span><span><a href={`tel:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t5}</a></span></li>
                    <li><span>Email :</span><span><a href={`mailto:${Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}`}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.contact_t6}</a></span></li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-md-2 col-sm-6">
              <div
                className="footer-three">
                
              </div>
            </div>
            <div
              className="col-md-3 col-sm-6">
              <div
                className="footer-three">
                <div
                  className="footerlist-two">
                  <h4>Quick Connect</h4>
                  <div
                    className="app-icons">
                    <ul
                      className="list-inline">
                      <li><a target={appStorel == ""? '' : "_blank"} href={appStorel == ""? 'javascript:;' : appStorel} className="hvr-float-shadow imggbg"><img src={appstore} className="img img-fluid" alt="appstore" /></a></li>
                      <li><a target={playStore == ""? '' : "_blank"} href={playStore == ""? 'javascript:;' : playStore} className="hvr-float-shadow imggbg2"><img src={googleplay} className="img img-fluid" alt="appstore" /></a></li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div> */}
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className="footer-social">
                  <ul className="list-inline">
                  {Object.keys(settings).length > 0 && 
                  settings.WebsiteSettings.social_link1 !=='' &&
                    <li><a className="foo-icon" target="_blank" href={facebook}><i className="fab fa-facebook-f" aria-hidden="true"></i>Facebook</a></li>
                  }
                                        {Object.keys(settings).length > 0 && 
                  settings.WebsiteSettings.social_link6 !=='' &&
                    <li><a className="foo-icon" target="_blank" href={instagram}><i className="fab fa-instagram" aria-hidden="true"></i>Instagram</a></li>
                                        }
                                        {Object.keys(settings).length > 0 && 
                  settings.WebsiteSettings.social_link2 !=='' &&
                    <li><a className="foo-icon" target="_blank" href={twitter}><i className="fab fa-twitter" aria-hidden="true"></i>Twitter</a></li>
                                        }
                    </ul>
                </div>

            </div>
          </div>
          <div className="row footerlist-bottom">
            <div className="col-md-12 col-xs-12">
              <ul>

                <li><NavLink to={"/termsAndConditions"}>Terms of Use and Legal Info</NavLink></li>
                <li><NavLink to={"/privacyPolicy"}>Privacy Policy</NavLink></li>
                <li><NavLink to={"/cookiePolicy"}>Cookie Preferences</NavLink></li>
                <li><a href=''>Guarantee and Cancellation Policy</a></li>  
              </ul>
              </div>
            </div>
          <div className="row  footer-sectionfour">
            <div className="col-md-12 col-xs-12">
              <p className="footer-last text-center">
                {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en} © {year}. All rights reserved. Powered by | <a target='_blank' href='https://bestatservices.com/' >www.bestatservices.com</a>
                {/* © <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}>{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_title_en}</a> 2023. All rights reserved. |
                Designed &amp; Developed By : <a href={Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url} target="_blank">{Object.keys(settings).length > 0 && settings.WebsiteSettings.site_url}</a> */}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer;